<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title">Division & Position</h2>
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-8">
            <div class="level">
              <div class="level-left">
                <b-button
                  class="is-command has-red-text"
                  icon-left="plus"
                  @click="openModalDivision()"
                >
                  New
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #page-content>
        <div id="divisions-table">
          <b-table
            :data="divisions"
            ref="table"
            narrowed
            detailed
            detail-key="id"
            :detail-transition="'fade'"
            :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
          >
            <b-table-column
              field="id"
              label="No"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.index + 1 }}
            </b-table-column>

            <b-table-column
              field="division_name"
              label="First Name"
              sortable
              v-slot="props"
            >
              {{ props.row.departmentName }}
            </b-table-column>

            <b-table-column
              field="action"
              label="Action"
              width="200"
              v-slot="props"
            >
              <b-icon
                icon="eye"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="props.toggleDetails(props.row)"
              />
              <b-icon
                icon="plus"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="openModalPosition(props.row)"
              />
              <b-icon
                icon="pencil"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="openModalEditDivision(props.row)"
              />
              <b-icon
                icon="delete"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="confirmDeleteDivision(props.row)"
              />
            </b-table-column>

            <template #detail="props" class="has-background-white">
              <!--- desktop only -->
              <div
                class="columns is-multiline is-mobile mr-6 ml-6 pr-6 pl-6 is-flex-desktop is-hidden-mobile detail-row"
                v-for="(position, index) in props.row.positions"
                :key="'desktop-' + position.id"
              >
                <div class="column is-1 is-mobile">
                  {{ props.index + 1 + '.' + (index + 1) }}
                </div>
                <div class="column is-8 is-mobile">
                  {{ position.positionName }}
                </div>

                <div class="column is-3 is-mobile">
                  <b-icon
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                    @click.native="openModalEditPosition(position, props.row)"
                  />
                  <b-icon
                    icon="delete"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                    @click.native="confirmDeletePosition(position, props.row)"
                  />
                </div>
              </div>

              <!--- mobile and tablet --->
              <div
                class="columns is-multiline is-mobile is-flex-mobile is-hidden-desktop detail-row"
                v-for="(position, index) in props.row.positions"
                :key="'mobile-' + position.id"
              >
                <div class="column is-1 is-mobile">
                  {{ props.index + 1 + '.' + (index + 1) }}
                </div>
                <div class="column is-8 is-mobile">
                  {{ position.positionName }}
                </div>
                <div class="column is-3 is-mobile">
                  <b-icon
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                    @click.native="openModalEditPosition(position)"
                  />
                  <b-icon
                    icon="delete"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                  />
                </div>
              </div>
            </template>
          </b-table>
        </div>

        <DivisionModal
          :show="isModalDivisionOpen"
          :loadingSubmit="isSavingDivision"
          :formData="formDivision"
          :isEditing="isEditing"
          @hide="closeModalDivision"
          @submit="saveDivision"
        />

        <PositionModal
          :show="isModalPositionOpen"
          :loadingSubmit="isSavingPosition"
          :formData="formPosition"
          :isEditing="isEditing"
          @hide="closeModalPosition"
          @submit="savePosition"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import { showToast } from '../../services/util'
import ApiService from '../../services/common/api.service'

// component
import DivisionModal from './Division/DivisionModal.vue'
import PositionModal from './Division/PositionModal.vue'

export default {
  data() {
    return {
      showDetailIcon: false,
      useTransition: false,
      divisions: [],
      isModalDivisionOpen: false,
      isLoadingDivision: false,
      isSavingDivision: false,
      formDivision: {
        divisionId: null,
        divisionName: null,
      },
      isSavingPosition: false,
      isModalPositionOpen: false,
      isEditing: null,
      formPosition: {
        positionId: null,
        positionName: null,
        departmentId: null,
        type: null,
      },
      currentPage: 0,
      lastPage: 0,
      perPage: 20,
      isLoadMore: false,
    }
  },
  methods: {
    /**
     * Load more division list table data
     */
    async loadMore() {
      if (this.currentPage < this.lastPage) {
        this.isLoadMore = true
        await this.fetchDivisionList()
        this.isLoadMore = false
      }
    },

    /**
     * Open division modal
     */
    openModalDivision() {
      this.isModalDivisionOpen = true
      this.isEditing = false
    },

    /**
     * Open position modal using division data
     * @param {Object} division - selected division object
     */
    openModalPosition(division) {
      this.formPosition.departmentId = division.id
      this.isModalPositionOpen = true
      this.isEditing = false
    },

    /**
     * Open division modal
     * @param {Object} division - selected division object
     */
    openModalEditDivision(division) {
      this.formDivision.divisionId = division.id
      this.formDivision.divisionName = division.departmentName
      this.isModalDivisionOpen = true
      this.isEditing = true
    },

    /**
     * Open edit position modal
     * @param {Object} position - selected position object
     * @param {Object} division - selected division object
     */
    openModalEditPosition(position, division) {
      this.formPosition.positionId = position.id
      this.formPosition.positionName = position.positionName
      this.formPosition.departmentId = division.id
      this.formPosition.type = position.type
      this.isModalPositionOpen = true
      this.isEditing = true
    },

    /**
     * Close division modal
     */
    closeModalDivision() {
      requestAnimationFrame(() => {
        this.$refs.observerDivision.reset()
      })
      this.formDivision.divisionId = null
      this.formDivision.divisionName = null
      this.isModalDivisionOpen = false
      this.isModalDivisionOpen = false
    },

    /**
     * Close position modal
     */
    closeModalPosition() {
      requestAnimationFrame(() => {
        this.$refs.observerPosition.reset()
      })
      this.formPosition.positionName = null
      this.formPosition.positionId = null
      this.formPosition.departmentId = null
      this.formPosition.type = null
      this.isModalPositionOpen = false
    },

    /**
     * save division form
     */
    async saveDivision() {
      this.isSavingDivision = true
      try {
        if (this.formDivision.divisionId) {
          // update
          let response = await ApiService.put(`/api/space-roketin/department`, {
            id: this.formDivision.divisionId,
            departmentName: this.formDivision.divisionName,
          })
          let divisionIndex = this.divisions.findIndex(
            (d) => d.id === this.formDivision.divisionId
          )
          if (divisionIndex >= 0) {
            this.divisions[divisionIndex].departmentName =
              response.data.data.departmentName
          }
        } else {
          // create
          let response = await ApiService.post(
            `/api/space-roketin/department`,
            {
              departmentName: this.formDivision.divisionName,
            }
          )
          let newDivision = response.data.data
          newDivision.positions = []
          this.divisions = [...this.divisions, newDivision]
        }
        showToast('Saved', 'is-success', 'is-top')
        this.closeModalDivision()
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isSavingDivision = false
    },

    /**
     * save position form
     */
    async savePosition() {
      this.isSavingPosition = true
      try {
        if (this.formPosition.positionId) {
          // edit
          let response = await ApiService.put(
            `/api/space-roketin/position/${this.formPosition.positionId}`,
            {
              positionName: this.formPosition.positionName,
              departmentId: this.formPosition.departmentId,
              type: this.formPosition.type,
            }
          )
          // update local state
          let divisionIndex = this.divisions.findIndex(
            (d) => d.id === this.formPosition.departmentId
          )
          if (divisionIndex >= 0) {
            let tempDivision = [...this.divisions]
            let positionIndex = tempDivision[divisionIndex].positions.findIndex(
              (p) => p.id === this.formPosition.positionId
            )
            if (positionIndex >= 0) {
              tempDivision[divisionIndex].positions[
                positionIndex
              ].positionName = response.data.data.positionName
              tempDivision[divisionIndex].positions[positionIndex].type =
                response.data.data.type
              this.divisions = tempDivision
            }
          }
        } else {
          // create
          let response = await ApiService.post(`/api/space-roketin/position`, {
            positionName: this.formPosition.positionName,
            departmentId: this.formPosition.departmentId,
            type: this.formPosition.type,
          })
          // update local state
          let divisionIndex = this.divisions.findIndex(
            (d) => d.id === this.formPosition.departmentId
          )
          if (divisionIndex >= 0) {
            let tempDivision = [...this.divisions]
            tempDivision[divisionIndex].positions.push(response.data.data)
            this.divisions = tempDivision
          }
        }
        showToast('Saved', 'is-success', 'is-top')
        this.closeModalPosition()
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isSavingPosition = false
    },

    /**
     * Open confirm dialog for delete position
     * @param {Object} position - selected position from table
     * @param {Object} division - selected division from table
     */
    confirmDeletePosition(position, division) {
      this.$buefy.dialog.confirm({
        title: 'Delete Position',
        message: `Are you sure you want to <b>delete ${position.positionName} </b> from division <b>${division.departmentName} </b> ? This action cannot be undone.`,
        confirmText: 'Delete Position',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deletePosition(position, division),
      })
    },

    /**
     * Open confirm dialog for delete division
     * @param {*} division - selected division from table
     */
    confirmDeleteDivision(division) {
      this.$buefy.dialog.confirm({
        title: 'Delete Division',
        message: `Are you sure you want to <b>delete ${division.departmentName} </b>? This action cannot be undone.`,
        confirmText: 'Delete Division',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteDivision(division),
      })
    },

    /**
     * Delete Division from database
     * @param {Object} division - selected division from table
     */
    async deleteDivision(division) {
      try {
        await ApiService.delete(`/api/space-roketin/department/${division.id}`)
        this.divisions = this.divisions.filter((d) => d.id !== division.id)
        showToast('Division Deleted', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
    },

    /**
     * Delete Position from database
     * @param {Object} position - selected position from table
     * @param {Object} division - selected division from table
     */
    async deletePosition(position, division) {
      try {
        await ApiService.delete(`/api/space-roketin/position/${position.id}`)
        // update local state
        let divisionIndex = this.divisions.findIndex(
          (d) => d.id === division.id
        )
        if (divisionIndex >= 0) {
          this.divisions[divisionIndex].positions = this.divisions[
            divisionIndex
          ].positions.filter((p) => p.id !== position.id)
        }
        showToast('Position Deleted', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
    },

    /**
     * Load division list data
     */
    async fetchDivisionList() {
      this.isLoadingDivision = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/department/collection',
          {
            perPage: this.perPage,
            page: ++this.currentPage,
          }
        )
        this.divisions = [...this.divisions, ...response.data.data]
        this.lastPage = response.data.lastPage
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isLoadingDivision = false
    },
  },
  async mounted() {
    await this.fetchDivisionList()
  },
  components: { DivisionModal, PositionModal },
}
</script>

<style lang="scss"></style>
