<template>
  <Modal
    :title="isEditing ? 'Edit Position' : 'Create Position'"
    :show="show"
    :class="'modal-allowed-capacity'"
    :submitLabel="isEditing ? 'Submit' : 'Add New'"
    :loadingSubmit="loadingSubmit"
    :isCancel="false"
    :isSubmit="true"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observerPosition" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(savePosition)"
          class="is-label-14-px-normal"
          multipart
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="position name"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Position Name"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    placeholder="Position Name"
                    v-model="formData.positionName"
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="time off type"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Time Off Type"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <div class="columns is-12 pt-2">
                    <div class="column is-4">
                      <b-radio v-model="formData.type" native-value="1">
                        Fixed Time Off
                      </b-radio>
                    </div>
                    <div class="column">
                      <b-radio v-model="formData.type" native-value="2">
                        +1 Every Month
                      </b-radio>
                    </div>
                  </div>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modals/Modal.vue'

export default {
  components: { Modal },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
